import Vue from "vue";
import axios from "axios";
import ElementUI from "element-ui";
import router from "@/router/index";
// 配置请求默认接口
axios.defaults.baseURL = "/api";
// 设置请求超时时间
axios.defaults.timeout = 15000
// 是否允许跨域携带cookie信息
// axios.defaults.withCredentials = true;
// // 配置请求头

// // http request 拦截器 添加一个请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 从 sessionStorage 中获取 token，存储在 'token' 键中
    const token = sessionStorage.getItem("token");

    //console.log("Token from sessionStorage:", tokenUrl); // 如果 token 存在，将 Authorization 头部添加到请求中
    if (token) {
    //  config.headers.Authorization = `${token}`;
    //  console.log("Authorization添加到请求t:", config.headers.Authorization);
    } else {
      try{
        router.currentRoute.path == "/"||router.currentRoute.path == "/registe" ? '' : router.push("/")
      }catch{

      }
   
    }
    // router.push("/login")
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// http response 拦截器 添加一个响应拦截器
axios.interceptors.response.use(
  (response) => {
   // console.log(router.currentRoute.path)
    switch (response.data.code) {
      case 401:
       // ElementUI.Message({
        //   message: "The account is not logged in!!!",
        //   type: "error",
        // });
        try{
      //  router.currentRoute.path == "/login" ? '' : router.push("/login")


        }catch{

        }
      
    }

    return response;
  },
  (error) => {
   // console.log(error)
    switch (error.response.status) {
      case 500:
        ElementUI.Message({
          message: "Server Abnormal！",
          type: "error",
        });
        try{
          // router.currentRoute.path == "/login" ? '' : router.push("/login")

        }catch{

        }
        break;
    }


    return Promise.reject(error);
 }
);

Vue.prototype.$axios = axios;
