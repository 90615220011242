<template>
  <div class="BOX">
    <div class="wh"></div>
    <div class="one-box3">
      <div class="one-box2-box">
       <img
          src="../assets/img/back.png"
          alt=""
          class="pic-back"
          @click="back"
        />
      </div>
      <div class="one-box2-box2">{{ language16.a16 }}</div>
      <div>&nbsp;</div>
    </div>
      <div class="wh"></div>
    <div class="one-box">
      <div class="one-box1">
        <div class="center">&nbsp;&nbsp;&nbsp;{{ language16.b16 }}</div>
        <div class="center">{{account}}&nbsp;&nbsp;&nbsp;</div>
      </div>
      <div class="xhx"></div>

      <div class="one-box1" @click="Modifylogin">
        <div class="center">&nbsp;&nbsp;&nbsp;{{ language16.c16 }}</div>
        <div class="center">
          <img src="../assets/img/backr.png" alt="" class="back2-pick" />
          &nbsp;&nbsp;&nbsp;
        </div>
      </div>
      <div class="xhx"></div>
<!--      <div class="one-box1" @click="ModifyPayment">-->
<!--        <div class="center">&nbsp;&nbsp;&nbsp;{{ language16.d16 }}</div>-->
<!--        <div class="center">-->
<!--          <img src="../assets/img/backr.png" alt="" class="back2-pick" />-->
<!--          &nbsp;&nbsp;&nbsp;-->
<!--        </div>-->
<!--      </div>-->
      <div class="xhx"></div>
      <Menu></Menu>
      <!-- <div class="one-box1"><div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;777</div><div class="box-2-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;777</div></div>
        <div class="one-box1"><div class="one-box2">&nbsp;&nbsp;&nbsp;&nbsp;888</div><div class="box-2-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;审核中</div></div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Menu from "../components/Menu.vue"
export default {
  components:{
    Menu
  },
  data() {
    return {
      account:"",
      items: [
        // {
        //   name: "555,",
        //   name1: "666",
        //   name2: "777",
        //   name3: "888",
        //   name4: "999",
        //   name5: "000",
        // },
      ],
      // 翻译数组
      language16: {
        a16: "修改密码",
        b16: "用户名",
        c16: "修改登录密码",
        d16: "修改支付密码",
      },
      languageChinese16: {
        a16: "修改密码",
        b16: "用户名",
        c16: "修改登录密码",
        d16: "修改支付密码",
      },
      languageEnglish16: {
        a16: "Change Password",
        b16: "Username",
        c16: "Change Login Password",
        d16: "Change Payment Password",
      },
      //繁体中文数组
      languageJT16: {
        a16: "修改密碼",
        b16: "用戶名",
        c16: "修改登錄密碼",
        d16: "修改支付密碼",
      },
      //泰语数组
      languageTY16: {
        a16: "แก้ไขรหัสผ่าน",
        b16: "ชื่อผู้ใช้",
        c16: "แก้ไขรหัสผ่านเข้าสู่ระบบ",
        d16: "เปลี่ยนรหัสผ่านการชำระเงิน",
      },

      //韩语数组
      languageHY16: {
        a16: "비밀번호 수정",
        b16: "사용자명",
        c16: "로그인 비밀번호 수정",
        d16: "결제 비밀번호 수정",
      },

      //日语数组
      languageRY16: {
        a16: "パスワードを変更",
        b16: "ユーザー名",
        c16: "ログインパスワードを変更",
        d16: "支払いパスワードを変更",
      },
      languageYDY16: {
        a16: "पासवर्ड संशोधित करें",
        b16: "उपयोगकर्ता नाम",
        c16: "लॉगिन पासवर्ड संशोधित करें",
        d16: "भुगतान पासवर्ड संशोधित करें",
      },
    };
  },
  methods: {
    ModifyPayment() {
      this.$router.push("/ModifyPayment");
    },
    Modifylogin() {
      this.$router.push("/Modifylogin");
    },
    back() {
      this.$router.go(-1);
    },
    into() {
      this.$router.push("/RecipientAccount");
    },
    getAccount() {
      axios
        .get(`/user/queryacc.do?id=${sessionStorage.getItem("token")}`)
        .then((res) => {
          this.account = res.data.data;
        });
    },
  },

  mounted() {
    this.getAccount();
    if (localStorage.getItem("language") == "chinese") {
      this.language16 = this.languageChinese16;
    }
    if (localStorage.getItem("language") == "english") {
      this.language16 = this.languageEnglish16;
    }
    if (localStorage.getItem("language") == "JT") {
      this.language16 = this.languageJT16;
    }
    if (localStorage.getItem("language") == "HY") {
      this.language16 = this.languageHY16;
    }
    if (localStorage.getItem("language") == "TY") {
      this.language16 = this.languageTY16;
    }
    if (localStorage.getItem("language") == "YDY") {
      this.language16 = this.languageYDY16;
    }
    if (localStorage.getItem("language") == "RY") {
      this.language16 = this.languageRY16;
    }
  },
};
</script>

<style scoped>
.BOX {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  background-color: rgb(217, 222, 222);
}
.one-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  /* margin-top: 15px; */

  background-color: rgb(217, 222, 222);
}
.one-box1 {
  display: flex;
  justify-content: space-between;
  height: 30px;
  color: rgb(144, 148, 148);
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.one-box2 {
  display: flex;
  width: 100%;
}
.one-box3 {
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: rgb(255, 255, 255);

}

.one-box2-box {
    display: flex;
  justify-content: center;
}
.one-box2-box2{
  display: flex;
justify-content: center;
  width: 50%;

}



.pic-back {
  width: 10px;
  height:auto;
}
.box-2-2 {
  width: 100%;
}
.xhx {
  width: 100%;
  height: 1px;
  background-color: rgb(208, 203, 203);
}
.back2-pick {
  width: 20px;
  height: 20px;
}
.center {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.wh{
  width: 100%;
  height: 10px;
  background-color: rgb(255, 255, 255);
}
</style>
